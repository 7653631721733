<template>
  <el-form
    class="addDrug"
    ref="form"
    :model="form"
    :rules="rules"
    label-width="100px"
  >
    <el-form-item label="提醒类型" prop="remindType">
      <el-radio-group v-model="form.remindType" @change="changeDrugName">
        <el-radio
          v-for="(item, index) in remindList"
          :key="item"
          :disabled="id ? true : false"
          :label="index"
        >
          {{ item }} </el-radio
        >>
      </el-radio-group>
    </el-form-item>
    <el-row :gutter="30">
      <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
        <el-form-item :label="tipsContent" prop="name">
          <el-input
            v-if="Number(searchId) === 3 || Number(searchId) === 4"
            v-model="form.name"
            :disabled="!form.remindType || id ? true : false"
            style="width: 300px"
            placeholder="请输入"
          />
          <el-select
            v-else
            v-model="form.name"
            :disabled="!form.remindType || id ? true : false"
            clearable
            filterable
            remote
            :remote-method="getMedicationList"
            reserve-keyword
            placeholder="请选择"
            style="width: 300px"
          >
            <el-option
              v-for="item in desiOptions"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            >
              {{ item.name + item.specs }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
        <el-form-item label="注意事项" prop="attention">
          <el-input
            v-model="form.attention"
            style="width: 300px"
            placeholder="请输入注意事项"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="开始日期" prop="startTime">
      <el-date-picker
        v-model="form.startTime"
        type="date"
        placeholder="选择日期"
      />
    </el-form-item>
    <div style="display: flex">
      <el-form-item label="提醒周期" prop="customizeCycleTime">
        <el-radio-group
          v-model="form.customizeCycleTime"
          @change="changeCustomizeCycleTime"
        >
          <el-radio
            v-for="(item, index) in customizeCycleTime"
            :key="item"
            :label="index"
          >
            {{ item }}
          </el-radio>
          <el-radio label="自定义"> 自定义 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="acustomize" prop="acustomizeCycle" label-width="0">
        <el-input
          v-model.number="form.acustomizeCycle"
          style="width: 300px; margin-left: 10px"
        />
      </el-form-item>
    </div>
    <el-form-item
      class="style_compatible"
      label="提醒时间"
      prop="customizeTime"
    >
      <el-checkbox v-model="form.checkAll" label="all" @change="handleCheckALL">
        每天
      </el-checkbox>
      <el-checkbox-group v-model="form.frequencies" @change="changeTipsTime">
        <el-checkbox
          v-for="item in frequencys"
          :key="item"
          :label="item"
          @change="changeFrequencys(item.frequency)"
        >
          {{ item }}
        </el-checkbox>
      </el-checkbox-group>

      <div style="display: flex; align-items: center">
        <el-radio-group
          v-model="form.customizeTime"
          style="margin-right: 10px"
          @change="handleCustomizeTime"
        >
          <el-radio
            v-for="(key, value) in customizeTime"
            :key="key"
            :label="value"
          >
            {{ key }}
          </el-radio>
          <el-radio label="自定义"> 自定义 </el-radio>
        </el-radio-group>
        <el-form-item
          v-if="isTips"
          style="margin-bottom: 0"
          prop="customizeTimeCycle"
        >
          <el-input
            v-model.number="form.customizeTimeCycle"
            style="width: 300px; margin-left: 10px"
          />
        </el-form-item>
      </div>
    </el-form-item>
    <div
      v-for="(item, index) in form.remindTimes"
      :key="index"
      style="display: flex"
    >
      <el-form-item
        :label="`第${index + 1}次提醒`"
        :prop="'remindTimes.' + index + '.remindTime'"
        :rules="{ required: true, message: '请输入', trigger: 'blur' }"
      >
        <el-time-select
          v-model="item.remindTime"
          start="06:00"
          step="00:15"
          end="22:00"
          placeholder="选择时间"
        />
      </el-form-item>
      <el-form-item v-if="showDrugNum" :label="lableVal">
        <div :gutter="10" style="display: flex">
          <el-form-item
            style="
              margin-bottom: 0;
              margin-right: 0;
              padding-left: 5px;
              padding-right: 5px;
            "
            :prop="'remindTimes.' + index + '.integer'"
            :rules="[
              {
                required: true,
                message: '请输入',
                trigger: ['blur', 'change'],
              },
              {
                type: 'number',
                message: '请输入数字',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input v-model.number="item.integer" style="width: 80px" />
          </el-form-item>
          <span v-if="isRun">·</span>
          <el-input
            v-if="isRun"
            v-model="item.floatNumber"
            style="width: 50px; padding-left: 5px; padding-right: 5px"
          />
          ·
          <el-form-item
            v-show="form.remindType === '1'"
            style="margin-bottom: 0; padding-left: 5px; padding-right: 5px"
            :prop="'remindTimes.' + index + '.dosageUnit'"
            :rules="{
              required: true,
              message: '请输入',
              trigger: ['blur', 'change'],
            }"
          >
            <el-select
              v-model="item.dosageUnit"
              placeholder="请输入"
              style="width: 100px"
            >
              <el-option
                v-for="item in options"
                :key="item.lable"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-show="form.remindType === '2'"
            style="margin-bottom: 0; padding-left: 5px; padding-right: 5px"
            :prop="'remindTimes.' + index + '.dosageUnit'"
            :rules="{
              required: true,
              message: '请输入',
              trigger: ['blur', 'change'],
            }"
          >
            <el-select
              v-model="item.dosageUnit"
              placeholder="请输入"
              style="width: 80px"
            >
              <el-option
                v-for="item in sportOptions"
                :key="item.lable"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form-item>
      <div class="delete" @click="deleteDate(index)">删除</div>

      <!-- <el-button
        type="danger"
        icon="el-icon-delete"
        circle
        @click="deleteDate(index)"
      ></el-button> -->
    </div>

    <el-button style="margin-left: 20px" @click="cliAdd"> 添加 </el-button>
    <div style="margin-top: 20px; margin-left: 300px; margin-bottom: 30px">
      <el-button @click="cancelTips"> 取消 </el-button>
      <el-button type="primary" @click="saveTips()"> 保存提醒 </el-button>
      <el-button v-if="id" type="danger" @click="deleteTips">
        删除提醒
      </el-button>
    </div>
  </el-form>
</template>

<script>
// import { ElMessage } from 'element-plus';
import cloneDeep from 'lodash/cloneDeep';
import {
  queryHealthRemindItem,
  addOrUpdateHealthRemind,
  queryDetail,
  // updateMedication,
  // getMedicationList,
  // getMedicationDetail,
  // deleteMedication,
} from '@/api/healthReminder';
import moment from 'moment';

export default {
  props: ['itemInfo', 'nickName', 'userId', 'cardId'],
  data() {
    const validatePass = (rule, value, callback) => {
      console.log('this.form', this);
      if (
        this.checkAll === true ||
        this.form.customizeTime !== '' ||
        this.form.frequencies.length !== 0
      ) {
        callback();
      } else {
        callback(new Error('请输入提醒时间'));
      }
    };
    const validateNumber = (rule, value, callback) => {
      console.log((value + '').split('').length);
      if (Number.isInteger(value)) {
        if ((value + '').split('').length >= 4) {
          callback(new Error('输入值不得大于3位'));
        } else {
          callback();
        }
      } else {
        callback(new Error('请输入数字值'));
      }
    };
    return {
      lableVal: '',
      isRun: true,
      isTips: false,
      showDrugNum: false,
      acustomizeCycle: '',
      customizeTimeCycle: '',
      acustomize: false,
      tipsContent: '提醒内容',
      value: '',
      searchId: '',
      desiOptions: [],
      checkList: ['监测提醒'],
      options: [
        { value: '粒', lable: '粒' },
        { value: '片', lable: '片' },
        { value: '包', lable: '包' },
        { value: '支', lable: '支' },
        { value: 'U', lable: 'U' },
        { value: '其他', lable: '其他' },
      ],
      // km、m、分钟、小时、个、次、组、步
      sportOptions: [
        { value: 'km', lable: 'km' },
        { value: 'm', lable: 'm' },
        { value: '分钟', lable: '分钟' },
        { value: '小时', lable: '小时' },
        { value: '个', lable: '个' },
        { value: '次', lable: '次' },
        { value: '组', lable: '组' },
        { value: '步', lable: '步' },
      ],
      form: {
        customizeTimeCycle: null,
        acustomizeCycle: null,
        remindType: '',
        longTerm: '1',
        name: '',
        startTime: '',
        creatorName: '',
        createdTime: '',
        customizeTime: '',
        customizeCycleTime: '',
        attention: '',
        checkAll: false,
        frequencies: [],
        remindTimes: [
          {
            remindTime: '',
            integer: '',
            floatNumber: '',
          },
        ],
      },
      remindList: {
        1: '用药提醒',
        2: '运动提醒',
        3: '监测提醒',
        4: '自定义提醒',
      },
      customizeCycleTime: {
        0: '长期',
        7: '一周',
        14: '两周',
        28: '一个月',
      },
      customizeTime: {
        1: '隔一天',
        7: '隔一周',
      },
      frequencys: [
        '每周一',
        '每周二',
        '每周三',
        '每周四',
        '每周五',
        '每周六',
        '每周日',
      ],
      rules: {
        remindType: [
          { required: true, message: '请选择提醒类型', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] },
          {
            min: 1,
            max: 20,
            message: '长度不得超过20个字符',
            trigger: ['blur', 'change'],
          },
        ],
        // attention: [
        //   { required: true, message: '请输入', trigger: 'blur' },
        //   { min: 2, max: 20, message: '长度至少2个字符,但不得超过20个字符', trigger: ['blur', 'change'] }
        // ],
        startTime: [
          { required: true, message: '请选择日期', trigger: 'change' },
        ],
        customizeCycleTime: [
          { required: true, message: '请选择提醒周期', trigger: 'change' },
        ],
        customizeTime: [
          { required: true, validator: validatePass, trigger: 'change' },
        ],
        customizeTimeCycle: [{ validator: validateNumber, trigger: 'blur' }],
        acustomizeCycle: [{ validator: validateNumber, trigger: 'blur' }],
      },
    };
  },
  created() {
    if (this.itemInfo.id) {
      this.getMedicationDetail();
    } else {
      this.form.startTime = moment(new Date()).format('YYYY-MM-DD');
      console.log(this.form.startTime);
    }
  },
  methods: {
    cancelTips() {
      this.$emit('changeDispaly', false);
    },
    deleteTips() {
      const data = {
        id: this.id,
      };
      deleteMedication(data).then((data) => {
        console.log(data);
        this.$emit('changeDispaly', false);
      });
    },
    changeFrequencys(val) {
      console.log('改变', val);
    },
    deleteDate(index) {
      if (this.form.remindTimes.length > 1) {
        this.form.remindTimes = this.form.remindTimes.filter((ele, i) => {
          return i !== index;
        });
      } else {
        this.$message.error(
          '需至少保留一条提醒时间。如需删除本提醒事宜，请在提醒列表中操作。'
        );
      }
    },
    changeCustomizeCycleTime(val) {
      if (val === '自定义') {
        this.acustomize = true;
      } else {
        this.form.acustomizeCycle = '';
        this.acustomize = false;
      }
    },
    // 根据提醒类型设置提醒内容
    changeDrugName(val) {
      this.desiOptions = [];
      this.form.remindTimes = [{}];
      this.form.name = '';
      this.searchId = val;
      if (Number(val) === 2) {
        this.tipsContent = '运动方式';
        this.isRun = false;
        this.showDrugNum = true;
        this.lableVal = '运动量';
      } else if (Number(val) === 1) {
        this.tipsContent = '药品名称';
        this.isRun = true;
        this.showDrugNum = true;
        this.lableVal = '本次用量';
      } else {
        this.tipsContent = '提醒内容';
        this.showDrugNum = false;
      }
      if (['1', '2'].includes(val)) {
        // 体验优化
        this.getMedicationList('');
      }
    },
    cliAdd() {
      if (this.form.remindTimes.length >= 12) {
        ElMessage.warning('提醒时间不能超过12条');
        return;
      } else {
        this.form.remindTimes.push({});
      }
    },
    getMedicationList(name) {
      this.desiOptions = [];
      const data = {
        pageSize: 10,
        pageIndex: 1,
        drugsOrSportsId: this.searchId,
        name: name,
        userId: this.userId,
      };
      queryHealthRemindItem(data).then((data) => {
        setTimeout(() => {
          this.desiOptions = data.data;
        }, 200);
      });
    },
    async getMedicationDetail() {
      const res = await queryDetail({
        id: this.itemInfo.id,
      });
      console.log(res, 11111111);

      // const data = {
      //   id: this.id,
      // };
      // getMedicationDetail(data).then((data) => {
      // console.log(this.itemInfo, 'itemInfo');
      let data = res.data;
      const frequencysLen = data.frequencys.length > 0;
      const medicationsLen = data.remindTimes.length > 0;
      const isNotTips = [1, 7].includes(data.customizeTime); // 隔一周隔一天
      const isshowDrugNum = [1, 2].includes(data.remindType); // 是否展示数值输入
      if (medicationsLen && isshowDrugNum) {
        // 回显用药提醒
        data.remindTimes.map((item) => {
          const { dosageNum } = item;
          const [integer = 0, floatNumber = 0] = dosageNum?.split('.');
          item.integer = +integer;
          item.floatNumber = +floatNumber;
        });
      }
      if (frequencysLen) {
        // 回显选中的每周几
        data.frequencies = data.frequencys.map((item) => item.frequency);
      }
      if (data.longTerm === '1') {
        // 兼容长期时customizeCycleTime返回null导致回显失效问题
        data.customizeCycleTime = data.customizeCycleTime || 0;
      }
      const isNotAcustomize = [0, 7, 14, 28].includes(data.customizeCycleTime); // 非自定义提醒周期
      if (isNotAcustomize) {
        data.customizeCycleTime = String(data.customizeCycleTime);
      }
      if (isNotTips) {
        data.customizeTime = String(data.customizeTime);
      }
      data.remindType = String(data.remindType);
      this.form = { ...this.form, ...data };
      this.searchId = data.remindType;
      if (data.remindType === '1') {
        this.lableVal = '本次用量';
      } else {
        this.lableVal = '运动量';
      }
      if (isNotAcustomize) {
        this.acustomize = false;
      } else {
        this.acustomize = true;
        this.form.acustomizeCycle = Number(this.form.customizeCycleTime);
        this.form.customizeCycleTime = '自定义';
      }
      if (isNotTips || frequencysLen) {
        this.isTips = false;
      } else {
        this.isTips = true;
        this.form.customizeTimeCycle = Number(this.form.customizeTime);
        this.form.customizeTime = '自定义';
      }
      if (isshowDrugNum) {
        this.showDrugNum = true;
      }
      if (data.remindType === '2') {
        this.isRun = false;
      }
      if (data.frequencys.length >= 7) {
        this.form.checkAll = true;
      }
      // });
    },
    saveTips() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const start = this.form.startTime;
          const d = new Date(start);
          const day = new Date();
          const times = moment(d).format('YYYY-MM-DD');
          if (Number(this.form.customizeCycleTime) !== 0) {
            this.form.longTerm = '0';
          } else {
            this.form.longTerm = '1';
          }
          const createdTime = moment(day).format('YYYY-MM-DD');
          console.log(this.form.frequencys);
          if (this.acustomize === true) {
            this.form.customizeCycleTime = Number(this.form.acustomizeCycle);
          }
          if (this.isTips === true) {
            this.form.customizeTime = Number(this.form.customizeTimeCycle);
          }

          const data = {
            ...cloneDeep(this.form),
            dataChannel: 'G',
            userId: this.userId,
            cardId: this.cardId,
            startTime: times,
            creatorName: this.nickName,
            createdTime,
          };

          // 兼容提醒时间frequencys入参
          if (data.frequencies && data.frequencies.length > 0) {
            const newFrequencys = [];
            data.frequencies.map((item) => {
              newFrequencys.push({
                frequency: item,
              });
            });
            data.frequencys = newFrequencys;
            delete data.frequencies;
          }
          // 兼容目标量入参
          data.remindTimes.map((item) => {
            const { integer, floatNumber } = item;
            if (['1', '2'].includes(this.form.remindType)) {
              item.dosageNum = `${integer}${
                floatNumber ? `.${floatNumber}` : ''
              }`;
            }
            delete item.integer;
            delete item.floatNumber;
          });
          try {
            await addOrUpdateHealthRemind(data);
            this.$refs.form.resetFields();
            // 关闭自定义的输入框
            this.acustomize = false;
            this.isTips = false;
            // 将数据清空
            this.form = {};
            this.form.frequencies = [];
            this.form.checkAll = false;
            this.$emit('changeDispaly', false);
          } catch (error) {
            console.log(error);
            if (typeof this.form.customizeTimeCycle === 'number') {
              this.form.customizeTime = '自定义';
            }
            if (typeof this.form.acustomizeCycle === 'number') {
              this.form.customizeCycleTime = '自定义';
            }
          }
        } else {
          console.log('保存失败');
          return false;
        }
      });
    },
    changeTipsTime(val) {
      this.isTips = false;
      this.form.customizeTime = '';
      if (val.length !== 7) {
        this.form.checkAll = false;
      } else {
        this.form.checkAll = true;
      }
    },
    handleCheckALL(val) {
      this.form.customizeTime = '';
      this.isTips = false;
      if (val) {
        this.form.frequencies = [
          '每周一',
          '每周二',
          '每周三',
          '每周四',
          '每周五',
          '每周六',
          '每周日',
        ];
      } else {
        this.form.frequencies = [];
      }
    },
    handleCustomizeTime(val) {
      if (val === '自定义') {
        this.isTips = true;
      } else {
        this.form.customizeTimeCycle = '';
        this.isTips = false;
      }
      if (val) {
        this.form.frequencies = [];
        this.form.checkAll = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addDrug {
  ::v-deep .style_compatible .el-form-item__content {
    display: block;
  }
}
.delete {
  margin-left: 20px;
  margin-top: 6px;
  font-size: 13px;
  color: red;
  line-height: 28px;
}
</style>
